import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./components/App";
import { AuthProvider } from "./context/AuthContext";

/**Redux store */

import store from "./redux/store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <AuthProvider>
      {" "}
      <App />
    </AuthProvider>
  </Provider>
);
